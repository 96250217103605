import "./style/contact.scss";

import * as React from "react";
import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";

import Layout from "../../components/layout/layout";
import MapContainer from "./components/google-map";
import Seo from "../../components/seo";
import axios from "axios";
import ogImage from "../../images/seo/SEO_fundacja.jpg";
import { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const FormResult = (props) => {
  if (!props?.title && !props?.desc) return null;
  return (
    <div className="contact-form__form__result">
      <div
        className={`contact-form__form__result__icon ${props.success ? "success" : "error"
          }`}
      ></div>
      <div className="contact-form__form__result__title">{props.title}</div>
      <p>{props.desc}</p>
    </div>
  );
};

const ContactPage = () => {
  const { t } = useTranslation();

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(t("contact.form.errors.required")),
    email: Yup.string()
      .email(t("contact.form.errors.email"))
      .required(t("contact.form.errors.required")),
  });

  const [status, setStatus] = useState(null);

  return (
    <Layout withHeader={false}>
      <Seo
        title={t("contact.page-title")}
        description={t("contact.page-description")}
        ogImage={ogImage}
      />
      <div className="contact-form">
        <section className="page-section">
          <div className="contact-form__header">
            <div className="contact-form__header__title">
              {t("contact.header")}
            </div>
            <div className="contact-form__header__subtext">
              {t("contact.subtitle")}
            </div>
          </div>
          <div className="contact-form__body">
            <div className="contact-form__items">
              <div className="contact-form__item-title">
                {t("contact.info.contact-details.title")}
              </div>
              <div className="contact-form__item">
                <div className="contact-form__item__social mb-3">
                  <div className="contact-form__item__social__contact">
                    <div className="contact-form__item__social__icon">
                      <a
                        href="mailto:hello@innovationshub.pl"
                        className="wrapper"
                      >
                        {" "}
                        <i className="icon email"></i>
                      </a>
                    </div>
                    <a href="mailto:hello@innovationshub.pl">
                      {t("contact.info.contact-details.email-us")}
                    </a>
                    &nbsp;
                    {t("contact.info.contact-details.email")}
                  </div>
                </div>
                <div className="contact-form__item__social">
                  <div className="contact-form__item__social__contact">
                    <div className="contact-form__item__social__icon">
                      <a href="tel:+48668574097" className="wrapper">
                        {" "}
                        <i className="icon phone"></i>
                      </a>
                    </div>
                    <a href="tel:+48668574097">
                      {t("contact.info.contact-details.call-us")}
                    </a>
                    &nbsp;
                    {t("contact.info.contact-details.phone-number")}
                  </div>
                </div>
              </div>
              <div className="contact-form__item-title">
                {t("contact.info.location.title")}
              </div>
              <div className="contact-form__item">
                <div className="contact-form__item__social mb-4">
                  <h6>{t("contact.info.location.socials")}</h6>
                  <div className="contact-form__item__social__icon">
                    <a
                      href="https://www.facebook.com/InnovationsHubFoundation/"
                      target="_blank"
                      className="wrapper"
                      rel="noreferrer"
                    >
                      <i className="icon facebook"></i>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/innovations-hub/"
                      target="_blank"
                      className="wrapper"
                      rel="noreferrer"
                    >
                      <i className="icon linkedin"></i>
                    </a>

                    <a
                      href="https://www.instagram.com/medbizinnovationsprogram/"
                      target="_blank"
                      className="wrapper"
                      rel="noreferrer"
                    >
                      <i className="icon instagram"></i>
                    </a>
                  </div>
                </div>
                <div className="contact-form__item__social">
                  <h6>{t("contact.info.location.stationary")}</h6>
                  <div className="contact-form__item__social__icon">
                    <a
                      href="https://goo.gl/maps/wxCXp4ygbMwCJiQ87"
                      target="_blank"
                      className="wrapper"
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="icon pin"></i>
                    </a>

                    <div>
                      {t("contact.info.address")}
                      <br />
                      {t("contact.info.nip")}
                      <br />
                      {t("contact.info.regon")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form__form">
              <FormResult {...status} />
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={ContactSchema}
                onSubmit={async (values, { setErrors }) => {
                  try {
                    const response = await axios.post(
                      "https://api.brevo.com/v3/smtp/email",
                      {
                        sender: { email: 'no-reply@innovationshub.pl' },
                        to: [{ email: 'hello@innovationshub.pl' }],
                        cc: [{ email: values.email }],
                        subject: "Próba kontaktu z formularza",
                        htmlContent: `<p>Imię: ${values.name}</p><p>Email: ${values.email}</p><p>Wiadomość: ${values.message}</p>`,
                      },
                      {
                        headers: {
                          "api-key": `${process.env.GATSBY_BREVO_API_KEY}`,
                          "Content-Type": "application/json",
                        },
                      }
                    );
                    setStatus({
                      success: true,
                      desc: t("contact.form.result.success.desc"),
                      title: t("contact.form.result.success.title"),
                    });
                  } catch (error) {
                    setStatus({
                      success: false,
                      desc: t("contact.form.result.error.desc"),
                      title: t("contact.form.result.error.title"),
                    });
                  }
                  setTimeout(() => {
                    setStatus(null);
                  }, 10 * 1000);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <label className="form-item-wrapper">
                      <span>{t("contact.form.fields.email.label")}</span>
                      <Field
                        id="email"
                        className={
                          errors?.email && touched?.email ? "error" : ""
                        }
                        name="email"
                        type="email"
                        placeholder={t("contact.form.fields.email.placeholder")}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>{t("contact.form.fields.name.label")}</span>
                      <Field
                        placeholder={t("contact.form.fields.name.placeholder")}
                        className={errors?.name && touched?.name ? "error" : ""}
                        id="name"
                        name="name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>{t("contact.form.fields.message.label")}</span>
                      <Field
                        as="textarea"
                        rows="4"
                        placeholder={t(
                          "contact.form.fields.message.placeholder"
                        )}
                        id="message"
                        name="message"
                      />
                    </label>
                    <p className="rodo">
                      {t("contact.form.rodo.info")}
                      &nbsp;
                      <a href="/rodo">{t("contact.form.rodo.clause")}</a>
                      &nbsp;
                      {t("contact.form.rodo.and")}
                      &nbsp;
                      <a href="/rodo">
                        {t("contact.form.rodo.privacy-policy")}
                      </a>
                      .
                    </p>
                    <Field
                      name="submit"
                      className="contact-form__form__submit"
                      type="submit"
                      value={t("contact.form.submit")}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
        <div className="google-map">
          <MapContainer />
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
